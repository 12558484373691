import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IGetAssignedCompanies,
  ILoginResponse,
  IRefreshTokenResponse,
  ISSOReturnUrl,
  ISSOReturnUrlRequest,
} from './identity.models';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../helpers/storage/storage.service';
import { ToastService } from '@/service/toast.service';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  private AUTH_API = `${environment.identity}/v2`;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  companyId: number = 0;

  getAuthMethods(email: string): Observable<IGetAssignedCompanies> {
    return this.http.get<IGetAssignedCompanies>(
      `${
        environment.api
      }/api/Profiles/GetAssignedCompanies?email=${encodeURIComponent(email)}`,
      httpOptions,
    );
  }

  login(
    email: string,
    password: string,
    companyId?: number,
  ): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(
      this.AUTH_API + '/Auth/Login',
      { email, password, companyId },
      httpOptions,
    );
  }

  refreshToken(refreshToken: string) {
    return this.http.post<IRefreshTokenResponse>(
      this.AUTH_API + '/Auth/RefreshToken',
      { refreshToken },
      httpOptions,
    );
  }

  logout() {
    this.storageService.clean();
    window.location.reload();
  }

  getReturnUrl(payload: ISSOReturnUrlRequest) {
    return this.http.post<ISSOReturnUrl>(
      `${environment.identity}/sso/setreturnurl`,
      payload,
    );
  }

  loginWithAuthCode(authCode: string) {
    return this.http.post<ILoginResponse>(
      `${this.AUTH_API}/Auth/LoginWithAuthCode`,
      { authCode },
    );
  }

  loginWithRefreshToken(refreshToken: string) {
    return this.http.post<ILoginResponse>(
      `${this.AUTH_API}/Auth/LoginWithRefreshToken`,
      { refreshToken },
    );
  }

  loginAs(impersonatedUserEmail: string) {
    return this.http.post<{
      accessToken: string;
      refreshToken: string;
      userId: number;
      userRole: string;
    }>(this.AUTH_API + '/Auth/LoginAs', {
      companyId: this.storageService.getCompanyId(),
      impersonatedUserEmail,
      accessToken: this.storageService.getAccessToken(),
      refreshToken: this.storageService.getRefreshToken(),
    });
  }

  logoutAs() {
    return this.http.post<{
      accessToken: string;
      refreshToken: string;
      userId: number;
      userRole: string;
    }>(this.AUTH_API + '/Auth/LogoutAs', {
      accessToken: this.storageService.getAccessToken(),
      refreshToken: this.storageService.getRefreshToken(),
    });
  }
}
